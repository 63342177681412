import ResultadoCliente from '../../components/FootId/Resultado/cliente'
import React from 'react'
import { graphql } from 'gatsby'

const R = () => {
  if (typeof document === 'undefined') {
    return <p></p>
  }
  return <ResultadoCliente hashid={document.location.hash.substring(1)} />
}

export default R

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
