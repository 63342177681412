import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SEO from '../../SEO'
import Layout from '../../Layout'
import BannerFootID from '../Banner'
import { HashidsFactory } from '../../../helpers/util'
import { pisadaResultado, ThumbResultadoPisada } from '../../../helpers/footId'
import { PISADAS } from '../../../constants/footId'

import bannerResultado from '../../../assets/images/foot-id/banner-resultado.jpg'

import * as S from './styles'
import { graphql } from 'gatsby'

const ResultadoCliente = ({ hashid, pageContext }) => {
  let hashids = HashidsFactory.create()
  if (pageContext && pageContext.hashid) {
    hashid = pageContext.hashid
  }

  const [id, peEsquerdo, peDireito] = hashids.decode(hashid)
  const [resultado, setResultado] = useState()

  useEffect(() => {
    const resultado = pisadaResultado(PISADAS[peEsquerdo], PISADAS[peDireito])

    if (!resultado) {
      console.log('deu ruim')
      //navigate('/foot-id')
    } else {
      setResultado(resultado)
    }
  }, [peEsquerdo, peDireito])

  return (
    <Layout>
      <SEO
        title="FOOT ID"
        description="Deixe seus pés falarem: Sua pisada pode definir tudo sobre você e a sua corrida"
      />

      <BannerFootID texto="resultados" src={bannerResultado} />

      {resultado && (
        <S.ResultadoContent>
          <S.ResultadoWrapper>
            <S.ResultadoThumb>
              <img
                src={ThumbResultadoPisada(peEsquerdo, peDireito)}
                alt={resultado.titulo}
              />
            </S.ResultadoThumb>
            <span>
              <S.ResultadoTitulo
                dangerouslySetInnerHTML={{ __html: resultado.titulo }}
              />
              <S.ResultadoTexto
                dangerouslySetInnerHTML={{ __html: resultado.descricao }}
              />
            </span>
          </S.ResultadoWrapper>
          <S.ResultadoTexto
            className="texto-adicional"
            dangerouslySetInnerHTML={{
              __html: resultado?.descricao_adicional,
            }}
          />
        </S.ResultadoContent>
      )}
    </Layout>
  )
}

ResultadoCliente.propTypes = {
  hashid: PropTypes.string,
}

export default ResultadoCliente

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
